import React from 'react'
import CartContext from '../../context/CartContext'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt
    } from "@fortawesome/pro-solid-svg-icons"
import styled from 'styled-components'

const Trash = styled.div`
    cursor: pointer;
    color: #003366;
    &:hover {
        color:#638FCB 
    }
`;

const RemoveLineItem = ({ lineItemId }) => {
    const {removeLineItem} = React.useContext(CartContext);

    const handleClick = () => {
        removeLineItem(lineItemId);
    }
    return (
        <Trash onClick={handleClick}>
            <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{
                        fontSize: '1.25rem',
                        marginRight: '5px',
                    }}
                    aria-hidden="true"
                    
                />
        </Trash>
    )
}

export default RemoveLineItem