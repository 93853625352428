import React from "react"
import CartContext from "../../context/CartContext"
import styled from "styled-components"
import Cart from "./Cart"
import QuantityAdjuster from "./QuantityAdjuster"
import RemoveLineItem from "./RemoveLineItem"
import { Button } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft,
        faAngleRight
    } from "@fortawesome/pro-solid-svg-icons"
import { navigate } from "gatsby"

const CartFooter = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 40px;
  > div {
    padding: 8px;
    &:first-child {
      text-align: right;
    }
  }
`

const CartHeader = styled.div`
  border-bottom: 1px solid gray;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 40px;
  font-weight: bold;
  > div {
    padding: 8px;
  }
`

const CartItem = styled.div`
  border-bottom: 1px solid lightgrey;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 40px;
  > div {
    padding: 8px;
    &:first-child {
      > div:first-child {
        font-weight: bold;
      }
      > div:last-child {
        color: #999;
        margin-top: 2px;
        font-size: 14px;
      }
    }
  }
`

const FooterButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div:last-child {
    text-align: right;
  }
`;

const CartContents = () => {
  const { checkout, updateLineItem } = React.useContext(CartContext)
  const handleAdjustQuantity = ({ quantity, variantId }) => {
    updateLineItem({quantity, variantId})
  }
  return (
    <>
      <h2>Your cart</h2>
      {!! checkout?.lineItems && (
        <CartHeader>
        <div>Product</div>
        <div>Unit Price</div>
        <div>Quantity</div>
        <div>Amount</div>
      </CartHeader>
      )}
      
      {checkout?.lineItems?.map(item => {
        
        const total = parseFloat(item.variant.price.amount).toFixed(2)
        console.log(total)
        
        return (
          <CartItem key={item.variant.id}>
          <div>
            <div>{item.title}</div>
            <div>
              {item.variant.title === "Default Title" ? "" : item.variant.title}
            </div>
          </div>
          <div>${parseFloat(item.variant.price.amount).toFixed(2)}</div>
        <div>
            <QuantityAdjuster item={item} onAdjust={handleAdjustQuantity} />      
        </div>
          <div>${(item.quantity * item.variant.price.amount).toFixed(2)}</div>
            <div><RemoveLineItem lineItemId={item.id} /></div>
        </CartItem>
        )
      }
      )}
      {!! checkout?.lineItems ? (
      <CartFooter>
        <div>
          <strong>Total:</strong>
        </div>
        <div>
          <span>${parseFloat(checkout?.totalPrice.amount).toFixed(2)}</span>
        </div>
      </CartFooter>
      ) : <h4>Your cart is empty</h4>}
      <FooterButtons>
          <div>
            <Button className="ussIdahoButton" onClick={() => navigate(-1)}>
            <FontAwesomeIcon
                    icon={faAngleLeft}
                    aria-hidden="true"
                    
                /> Continue Shopping
            </Button>
          </div>
          <div>
            {!!checkout?.webUrl && 
          <Button className="ussIdahoButton"
            onClick={() => {
              window.location.href = checkout.webUrl;
          }}>
          Checkout {' '}
            <span><FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                        
                        marginleft: '10px',
                    }}
                    aria-hidden="true"
                    
                /></span>
            </Button> }
          </div>
        </FooterButtons>
    </>
  )
}

export default CartContents
