import React from 'react'
import styled from 'styled-components'

const QuantityAdjusterWrapper = styled.div`
    display: flex;
    > div {
        margin: auto, 0;
        padding: 5px 10px
    }
`
const AdjusterButton = styled.div`
    cursor: pointer;
    border: 1px solid darkgrey;
    font-weight: bold
`;

const QuantityAdjuster = ({ item, onAdjust }) => {
    const { quantity } = item;

    const handleDecrementQuantity = () => {
        onAdjust({variantId: item.variant.id, quantity: -1})
    }

    const handleIncrementQuantity = () => {
        onAdjust({variantId: item.variant.id, quantity: 1})
    }

    return (
        <>
        {item.variant.id === "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzg2Mzg2OTkwNzExNA==" ? (<div>{quantity} (limit 1 per customer)</div>)  : (
            <QuantityAdjusterWrapper>
            <AdjusterButton onClick={handleDecrementQuantity}>
                -
            </AdjusterButton>
            <div>{quantity}</div>
            <AdjusterButton onClick={handleIncrementQuantity}>
                +
            </AdjusterButton>
        </QuantityAdjusterWrapper>
        ) }
        </>
    )
}

export default QuantityAdjuster