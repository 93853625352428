import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import PageTitle from "../components/PageTitle/PageTitle"
import ContentBlock from "../components/ContentBlock/ContentBlock"
import CartContents from "../components/Cart/CartContents"

const CartPage = () => {
  return (
    <Layout>
      <SEO title="Cart" />
      <Container fluid className="container-fluid-full">
        <PageTitle subPage="Shopping Cart" pageTitle="Shopping Cart " link="/">
          {/* {page.pageTitle} */}
        </PageTitle>
        <ContentBlock>
          <CartContents />
        </ContentBlock>
      </Container>
    </Layout>
  )
}

export default CartPage
